import { Layout } from '@/components/Shared/Layout/Layout';
import { LiveChat } from '@/components/Shared/LiveChat';
import { PageNotFound } from '@/components/Shared/PageNotFound';
import { isPreviewMode } from '@/utils/helpers/isPreviewMode';
import { IStoryBase, IStoryPage } from '@/utils/interfaces/stories';
import { resolvedRelations } from '@/utils/resolvedRelation';
import {
	StoryblokComponent,
	useStoryblokApi,
	useStoryblokState,
} from '@storyblok/react';
import { GetServerSideProps } from 'next';
import Head from 'next/head';
import { Fragment } from 'react';

interface IProps {
	story: IStoryBase<IStoryPage>;
	preview: boolean;
}

const Page: React.FC<IProps> = ({ story, preview, ...props }) => {
	const storyblokStory = useStoryblokState(story, {
		resolveRelations: resolvedRelations,
	});

	if (storyblokStory.content) {
		return (
			<Fragment>
				<LiveChat />
				<Layout>
					<StoryblokComponent
						story={storyblokStory}
						blok={storyblokStory.content}
					/>
				</Layout>
			</Fragment>
		);
	}

	return (
		<Fragment>
			<Head>
				<title>Page Not Found | canibuild</title>
			</Head>
			<LiveChat />
			<Layout>
				<PageNotFound />
			</Layout>
		</Fragment>
	);
};

export const getServerSideProps: GetServerSideProps = async (context) => {
	const { locale } = context;
	const preview = isPreviewMode(context.query);
	const slug: string[] | string = context.query.slug
		? context.query.slug
		: [];

	if (locale) {
		(slug as string[]).unshift(locale);
	}

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const storyblokApi = useStoryblokApi();

	if (preview) {
		let { data } = await storyblokApi.get(
			`cdn/stories/${context.query._storyblok}`,
			{
				version: 'draft',
				resolve_relations: resolvedRelations,
			},
		);

		return {
			props: {
				story: data ? data.story : false,
				preview: true,
			},
		};
	} else {
		return storyblokApi
			.get(`cdn/stories/${(slug as string[]).join('/')}`, {
				resolve_relations: resolvedRelations,
				version:
					process.env.NODE_ENV === 'development'
						? 'draft'
						: 'published',
			})
			.then((response: any) => {
				return {
					props: {
						story: response.data.story
							? response.data.story
							: false,
						preview: false,
					},
				};
			})
			.catch(() => {
				return {
					notFound: true,
					props: {
						story: false,
						preview: false,
					},
				};
			});
	}
};

export default Page;
